import { Injectable } from '@angular/core';
import { UserInfo, ConfigValue } from '@longnecktech/splash-commons-fe';
import { Game } from '@shared/types/game';
import { InfoContentDialogType } from '@shared/types/info-content-dialog-type';
import { BehaviorSubject, map } from 'rxjs';
import { Theme } from '@shared/types/theme';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  private _isMobile = new BehaviorSubject<boolean>(false);
  private _user = new BehaviorSubject<UserInfo | undefined>(undefined);
  private _game = new BehaviorSubject<Game | undefined>(undefined);
  private _theme = new BehaviorSubject<Theme | undefined>(undefined);
  private _infoContentDialogType = new BehaviorSubject<
    InfoContentDialogType | undefined
  >(undefined);

  thirdPartyToken = '';
  gameUuid?: string;
  instance?: string;
  isFromHub = false;
  readonly user$ = this._user.asObservable();
  readonly isMobile$ = this._isMobile.asObservable();
  readonly game$ = this._game.asObservable();
  readonly prizes$ = this.game$.pipe(
    map((game) => (game?.segments || []).filter((seg) => !!seg.assetLabel)),
  );
  readonly theme$ = this._theme.asObservable();
  readonly thereAnimation$ = this.theme$.pipe(
    map((theme) => theme?.backgroundAnimation),
  );
  readonly infoContentDialogType$ = this._infoContentDialogType.asObservable();

  setUser(userInfo: UserInfo): void {
    this._user.next(userInfo);
  }

  setIsMobile(isMobile: boolean): void {
    this._isMobile.next(isMobile);
  }

  setCurrentGame(game: Game): void {
    this._theme.next(game.theme);
    this._game.next(game);
    this.gameUuid = game.uuid;
  }

  getThemeLabels(): ConfigValue[] | undefined {
    return this._theme.value?.labels;
  }

  updateInfoContentDialogType(type: InfoContentDialogType | undefined): void {
    this._infoContentDialogType.next(type);
  }

  getUserLanguage(): string | undefined {
    return this._user.value?.language;
  }
}
